<template>
  <v-dialog
    v-model="showDialog"
    transition="dialog-bottom-transition"
    max-width="500"
    persistent
  >
    <template #activator="{ on }">
      <div class="d-flex justify-end mt-3">
        <v-btn
          class="primary white--text"
          small
          :disabled="disabled"
          v-on="on"
        >
          <v-icon
            class="mr-2"
            small
          >
            mdi-export
          </v-icon>
          {{ $t('buttons.lead.export.export-crm') }}
        </v-btn>
      </div>
    </template>
    <v-card>
      <v-card-title
        class="pa-0"
      >
        <v-toolbar
          color="primary"
          dark
          flat
        >
          <v-toolbar-title>{{ $t('crm-synchronisation.title') }}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="closeDialog"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>

      <v-card-text class="mt-5">
        <div v-if="isIdle">
          <p
            v-html="$t('crm-synchronisation.subtitle', { readableLead, crmLabel })"
          />
          <p>{{ $t('crm-synchronisation.disclaimer', { crmLabel }) }}</p>
          <v-select
            v-model="selectedStatus"
            :items="statusList"
            :item-disabled="disabledStatus"
            :menu-props="{ maxHeight: 340 }"
            label="Status"
            hide-details
            outlined
            dense
            @change="handleStatusChange"
          />
        </div>

        <div v-if="isSynchronizing">
          <span class="font-weight-bold mr-1">{{ readableLead }}</span> wird zu {{ crmLabel }} exportiert...
          <v-progress-linear
            color="primary"
            class="mt-4"
            indeterminate
          />
        </div>

        <v-alert
          v-if="isSuccesfull"
          icon="mdi-check-circle"
        >
          <span><b>{{ readableLead }}</b> wurde erfolgreich zu {{ crmLabel }} exportiert.</span>
        </v-alert>

        <v-alert
          v-if="hasError"
          type="error"
        >
          <span>{{ error }}</span>
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          class="grey--text"
          text
          @click="closeDialog"
        >
          {{ $t('labels.close') }}
        </v-btn>
        <v-btn
          v-if="!isFinished"
          text
          color="primary"
          class="ml-2"
          :disabled="isSynchronizing"
          @click="exportLeadToCrm"
        >
          {{ $t('buttons.crm-synchronisation.cta') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import bus, { eventNames } from '@/lib/eventBus'
import EXPORT_LEAD_TO_CRM from './queries/ExportLeadToCrm.gql'
import { statusForSelect } from '@/lib/leadStatus'
import trackingEvents from '@/lib/trackingEvents'
import UPDATE_LEAD_STATUS from './queries/UpdateLeadStatus.gql'
import LEAD from './queries/Lead.gql'
import brandingMixin from '@/mixins/branding'

export default {
  mixins: [brandingMixin],
  props: {
    crmLabel: {
      type: String,
      default: ''
    },
    lead: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showDialog: false,
      state: 'IDLE',
      error: null,
      selectedStatus: this.lead.currentStatus,
      statusList: statusForSelect.map(item => { // create translated status list
        return {
          ...item,
          text: this.$t(item.text)
        }
      })
    }
  },
  computed: {
    isIdle () {
      return this.state === 'IDLE'
    },
    isSynchronizing () {
      return this.state === 'IN_PROGRESS'
    },
    isFinished () {
      return this.state === 'FINISHED'
    },
    isSuccesfull () {
      return this.isFinished && this.error === null
    },
    hasError () {
      return this.isFinished && this.error
    },
    readableLead () {
      return this.lead.firstname ? `${this.lead.firstname} ${this.lead.lastname}` : this.lead.email
    }

  },
  methods: {
    disabledStatus ({ value }) {
      return this.status !== 'created' && value === 'created'
    },

    async handleStatusChange () {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_LEAD_STATUS,
          variables: {
            leadId: this.lead.id,
            status: this.selectedStatus
          },
          refetchQueries: [
            { query: LEAD, variables: { id: this.lead.id } }
          ]
        })

        window.Appcues.track(trackingEvents.LEAD_STATUS_UPDATED, {
          newStatus: this.selectedStatus
        })
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.lead.status.success') })
      } catch (e) {
        this.selectedStatus = this.lead.currentStatus
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.lead.status.error') })
      }
    },
    async exportLeadToCrm () {
      this.state = 'IN_PROGRESS'
      setTimeout(async () => {
        try {
          const { data: { exportLeadToCrm } } = await this.$apollo.mutate({
            mutation: EXPORT_LEAD_TO_CRM,
            variables: {
              id: this.lead.id
            },
            refetchQueries: [
              { query: LEAD, variables: { id: this.lead.id } }
            ]
          })

          if (exportLeadToCrm) {
            bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.crm-synchronisation.success') })
          }
        } catch (e) {
          if (e.graphQLErrors?.[0]?.message === 'NO_ACTIVE_CRM') {
            this.error = this.$t('alerts.crm-synchronisation.error-crm')
          } else {
            this.error = this.$t('alerts.crm-synchronisation.error-sync')
          }
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.crm-synchronisation.error') })
        } finally {
          this.state = 'FINISHED'
        }
      }, 2000)
    },
    closeDialog () {
      this.showDialog = false
      this.state = 'IDLE'
      this.error = null
    }
  }

}
</script>
